
          $VUE_APP_BASE: "";
        

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loading-spinner-wrap {
    position: fixed;
    background: #F2F3F7;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    margin: 0;
    top: 0;
    left: 0;
}

.spinner {
    display: block;
    width: 26px;
    height: 26px;
    margin: auto;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
